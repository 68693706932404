/* src/App.css */
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: 
      url('./components/assets/background.jpg');
  background-size: cover;
  background-blend-mode: overlay;
  background-repeat:repeat ;
  height: 100vh;
  display: flex;
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 5rem; /* Add some padding if needed */
  box-sizing: border-box; /* Include padding in height calculation */
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
}

/* Additional styles to manage the overflow */
.App::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

.App::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar color */
  border-radius: 4px; /* Rounded corners */
}

.App::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Scrollbar color on hover */
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
}

html, body, div, span, applet, object, iframe,  h2, h3, h4, h5, h6, p, a, abbr, img, ins, s, samp, small, var, b, u, i, center, figure footer, header menu, nav, section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}